import { base } from "@/events/common";

const open = {
  ...base,
  name: "sd-control-open",
};

// Указывется наименование корневого блока из json
const about_declaration = {
  ...base,
  name: "sd-control-about-declaration",
};

const type_declaration = {
  ...base,
  name: "sd-control-type-declaration",
};

const registration_details = {
  ...base,
  name: "sd-control-registration-details",
};

const subject = {
  ...base,
  name: "sd-control-subject",
};

const counter_agent = {
  ...base,
  name: "sd-control-counter-agent",
};

const deal_details = {
  ...base,
  name: "sd-control-deal-details",
};

const filler = {
  ...base,
  name: "sd-control-filler",
};

const ware_shipments = {
  ...base,
  name: "sd-control-ware-shipments"
};
const wares = {
  ...base,
  name: "sd-control-wares", //  Дополняется index
};

const presented_documents = {
  ...base,
  name: "sd-control-presented-documents",
};

const about_wares = {
  ...base,
  name: "sd-control-about-wares",
};

const declarant = {
  ...base,
  name: "sd-control-declarant",
};

const declaration_payments = {
  ...base,
  name: "sd-control-declaration-payments",
};

const cargo_route = {
  ...base,
  name: "sd-control-cargo-route",
};

const sender = {
  ...base,
  name: "sd-control-sender",
};

const recipient = {
  ...base,
  name: "sd-control-recipient",
};

const buyer = {
  ...base,
  name: "sd-control-buyer",
};

const seller = {
  ...base,
  name: "sd-control-seller",
};

const principal = {
  ...base,
  name: "sd-control-principal",
};

const carrier = {
  ...base,
  name: "sd-control-carrier",
};

const carrier_eeu = {
  ...base,
  name: "sd-control-carrier_eeu",
};

const driver = {
  ...base,
  name: "sd-control-driver",
};

const transport = {
  ...base,
  name: "sd-control-transport",
};

const transports = {
  ...base,
  name: "sd-control-transports",
};

const about_transport = {
  ...base,
  name: "sd-control-about-transport",
};

const destination_customs = {
  ...base,
  name: "sd-control-destination-customs",
};

destination_customs

const reloads = {
  ...base,
  name: "sd-control-reloads",
};
const guarantees = {
  ...base,
  name: "sd-control-guarantees",
};

const about_guarantee = {
  ...base,
  name: "sd-control-about-guarantee",
}

const payer = {
  ...base,
  name: "sd-control-payer",
}

const ware_location = {
  ...base,
  name:"sd-control-ware-location"
}

const previous_reg_number = {
  ...base,
  name:"sd-control-previous-reg-number"
}

export {
  open,
  about_declaration,
  subject,
  type_declaration,
  counter_agent,
  deal_details,
  filler,
  registration_details,
  presented_documents,
  wares,
  about_wares,
  declarant,
  declaration_payments,
  cargo_route,
  sender,
  recipient,
  principal,
  carrier,
  carrier_eeu,
  driver,
  transport,
  transports,
  reloads,
  guarantees,
  ware_shipments,
  about_transport,
  destination_customs,
  about_guarantee,
  payer,
  ware_location,
  previous_reg_number,
  buyer,
  seller
};
